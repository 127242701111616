import 'react-app-polyfill/stable'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/number/is-nan'
import 'core-js/features/string/match-all'

import 'megical-react-components/lib/base.scss'
import 'helpers.sass'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import Loadable from 'react-loadable'
// Clear globalError.js handler. ErrorBoundary shows errors when errors do happen.
// globalError.js is for incompatible browsers that don't support modern javascript.
window.onerror = null
// hopefylly will not fail
const Loading = ({ error }) => {
  if (error) {
    return <center>Error loading page</center>
  } else {
    return <div className="init-loader"></div>
  }
}
const AsyncBaseWrapper = Loadable({
  loader: () => import('./BaseWrapper'),
  loading: Loading
})

ReactDOM.render(<AsyncBaseWrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
